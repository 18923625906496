<template>
  <div class="datadownload">
      <div class="nav">
          <H5Carousel/>
      </div>
       <div class="content">
          <div class="navs">
              <van-collapse v-model="activeNames">
                <van-collapse-item :title="navtitle">
                    <div class="titleitem" v-for="(item,index) in nav" @click="gopage(index)" :key="index" :style="{background:item.background,color:item.color}">
                        {{show == true?item.title:item.titles}}
                    </div>
                </van-collapse-item>
              </van-collapse>
          </div>
         <div class="content">
            <Datadownload :getdata="getdatas"/>
         </div>
      </div>
      <div class="bot">
          <H5Bottom/>
      </div>
  </div>
</template>

<script>
import Datadownload from "../../components/h5datadownload/datadownload.vue";
import H5Carousel from '../../components/h5-carousel.vue'
import H5Bottom from '../../components/h5bottom.vue'
export default {
     components:{
        H5Carousel,
        H5Bottom,
        Datadownload,
    },
    data() {
        return {
            nav:[
                {title:"展馆平面图",background: "#197cc3",color: "#fff",titles:"Hall plan"},
                {title:"场地动线图",background: "#fff",color: "#000",titles:"Site dynamic line map"},
                {title:"宣传手册",background: "#fff",color: "#000",titles:"Brochure"},
                {title:"管理规定",background: "#fff",color: "#000",titles:"Management regulations"},
                {title:"报批文件",background: "#fff",color: "#000",titles:"Approval document"},
            ],
            activeNames: ['0'],
            navtitle:"",
            getdatas:[],
            show:""
        }
    },
    created() {
         if(this.$route.query.path == undefined || this.$route.query.path == '/'){
             this.getdata("1")
             if(localStorage.getItem('locale') == 'en-US'){
                 this.navtitle = 'Hall plan'
            }else{
                 this.navtitle = '展馆平面图'
            }
         }else{
             this.navtitle = this.$route.query.path
              let nav = this.nav
                let navlist =  nav.map((item,index)=>{
                    if(item.title == this.$route.query.path){
                        this.getdata(index+1)
                        item.background = '#197cc3'
                        item.color = '#fff'
                         if(localStorage.getItem('locale') == 'en-US'){
                            this.navtitle = item.titles
                        }else{
                            this.navtitle = item.title
                        }
                    }else{
                        item.background = '#fff'
                        item.color = '#000' 
                    }
                    return item
                })
                if(localStorage.getItem('locale') == 'en-US'){
                    this.show = false
                }else{
                    this.show = true
                }
             this.nav = navlist
         }
    },
    methods:{
        // 获取下载文件
        getdata(val){
            let data = {
                pageNum:"1",
                pageSize:"99999",
                type:val
            }
            this.$api.datadownload(data,res=>{
                if(res.data.code == 200){
                    this.getdatas = res.data.rows.map((item)=>{
                        item.class = item.url.substring(item.url.length-3,item.url.length)
                        return item
                    })
                }
            })
        },
        gopage(val){
            let nav = this.nav
           let navlist =  nav.map((item,index)=>{
                if(index == val){
                    item.background = '#197cc3'
                    item.color = '#fff' 
                }else{
                    item.background = '#fff'
                    item.color = '#000' 
                }
                return item
            })
            this.nav = navlist
           if(val == 0){
                if(localStorage.getItem('locale') == 'en-US'){
                    this.navtitle = 'Hall plan'
                }else{
                    this.navtitle = '展馆平面图'
                }
               this.getdata("1")
               this.$router.replace({path:'/datadownload',query:{path:`展馆平面图`}})
               this.activeNames = ["0"]
           }else if(val == 1){
                if(localStorage.getItem('locale') == 'en-US'){
                    this.navtitle = 'Site dynamic line map'
                }else{
                    this.navtitle = '场地动线图'
                }
               this.getdata("2")
               this.$router.replace({path:'/datadownload',query:{path:`场地动线图`}})
               this.activeNames = ["1"]
           }else if(val == 2){
                if(localStorage.getItem('locale') == 'en-US'){
                    this.navtitle = 'Brochure'
                }else{
                    this.navtitle = '宣传手册'
                }
               this.getdata("3")
               this.$router.replace({path:'/datadownload',query:{path:`宣传手册`}})
               this.activeNames = ["2"]
           }else if(val == 3){
                if(localStorage.getItem('locale') == 'en-US'){
                    this.navtitle = 'Management regulations'
                }else{
                    this.navtitle = '管理规定'
                }
               this.getdata("4")
               this.$router.replace({path:'/datadownload',query:{path:`管理规定`}})
               this.activeNames = ["3"]
           }else if(val == 4){
                if(localStorage.getItem('locale') == 'en-US'){
                    this.navtitle = 'Approval document'
                }else{
                    this.navtitle = '报批文件'
                }
               this.getdata("5")
               this.$router.replace({path:'/datadownload',query:{path:`报批文件`}})
               this.activeNames = ["4"]
           }
        }
    }
}
</script>

<style scoped>
    .datadownload{
        box-sizing: border-box;
    }
    .imgs:nth-of-type(1){
        width: 0.76rem;
        height: 0.88rem;
        display: block;
        float: left;
        margin-right: 0.2rem;
    }
    .content{
        width: 100%;
    }
    .navs{
        width: 100%;
        height: auto;
    }
    .navs >>> .van-cell{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-bottom: 0.01rem solid #999;
        font-size: 0.16rem;
        height: 0.54rem;
    }
    .navs >>> .van-cell__title{
        flex: none;
    }
    .titleitem{
        width: 100%;
        height: 0.62rem;
        line-height: 0.62rem;
        text-align: center;
        font-size: 0.16rem;
    }
</style>