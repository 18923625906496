<template>
  <div class="temp">
      <div class="content">
         <div class="contents" v-for="(item,index) in getdata" :key="index" @click="uploaddata(item)">
           <p class="imgs" v-if="item.class == 'jpg' || item.class == 'peg' || item.class == 'png' || item.class == 'gif' || item.class == 'bmp' || item.class == 'ebp'">
             
            <viewer @inited="inited" :ref="index" width="0.75rem">
              <img  @click.stop="prev(item)" :src="item.url" alt="">
            </viewer>
            </p>
           <p class="imgs" @click.stop="preve(item)" v-else><img src="../../assets/image/d455504e35e2119f9c1753a4af34db8.png" alt=""></p>
           <p class="tite">{{item.name}}</p>
         </div>
       </div>
  </div>
</template>

<script>
export default {
    props:["getdata"],
    methods: {
      inited(viewer) {
				this.$viewer = viewer
			},
      prev(){
        this.$viewer.show()
      },
      preve(val){
         window.open(val.url)
      },
      uploaddata(val){
      let data = {
        name:val.name,
        url:window.location.origin+val.url
      }
      this.$api.uploaddata(data,res => {
          let url = window.URL.createObjectURL(new Blob([res.data]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", val.name); //指定下载后的文件名，防跳转
          document.body.appendChild(link);
          link.click();
        })
    },
    },
}
</script>

<style scoped>
  .temp{
    width: 100%;
    height: auto;
    padding: 0 0.08rem;
    box-sizing: border-box;
    background: #fafafa;
  }
  .contents{
    width: 100%;
    height: 2rem;
    background: #fff;
    margin: 0.08rem 0;
  }
  .imgs{
    width: 100%;
    height: calc(100% - 0.64rem);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tite{
    width: 100%;
    height: 0.64rem;
    line-height: 0.64rem;
    text-align: center;
    background: #bfd4e3;
  }
  .imgs img{
    width: 0.75rem;
    height: 1rem;
  }
</style>